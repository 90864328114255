<template>
  <Page class="page-shop page-shop-settings page-shop-settings-web" v-if="settings">
    <template #title>Personalizza vetrina</template>

    <template #title-extra>
      <router-link v-if="isEdit" class="btn btn-outline-light" :to="{ name: 'shop.settings.web' }">Annulla modifica</router-link>
      <router-link v-else class="btn btn-outline-light" :to="{ name: 'shop.settings.web.edit', params: { edit: 'edit' } }">Modifica</router-link>
    </template>

    <template #default>
      <ShopWebForm v-if="isEdit" :feedback.sync="feedback" :settings="settings" @submitForm="submit" edit />
      <template v-else>
        <p>Nome: {{ settings.name }}</p>
        <p>Lingua: {{ settings.language }}</p>
        <p>Categoria: {{ settings.category_name }}</p>
        <p>Descrizione: {{ settings.description }}</p>
        <p>Email: {{ settings.email }}</p>
        <p>Numero di telefono: {{ settings.phone }}</p>
        <p>Sito web: {{ settings.website }}</p>
        <p>Indirizzo: {{ settings.address }}</p>
        <p>Note a fondo pagina: {{ settings.footerNotes || '-' }}</p>
      </template>
    </template>
  </Page>
  <span v-else></span>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import Page from '@/views/components/Private/Page.vue';
import ShopWebForm from '@/views/components/Form/ShopWebSettingsForm.vue';

export default {
  components: {
    Page,
    ShopWebForm,
  },
  data: () => ({
    settings: {},
    feedback: null,
  }),
  computed: {
    isEdit () {
      return this.$route?.params?.edit;
    },
  },
  watch: {
    $route () {
      this.feedback = null;
    },
  },
  methods: {
    submit (settings) {
      this.feedback = null;

      this.$api.updateMyShopWebSettings(settings)
        .then(res => {
          this.settings = res.data;
          this.$router.replace({ name: 'shop.settings.web' });
          this.$oauth2.refreshMe();
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
  mounted () {
    this.feedback = null;
  },
  beforeRouteEnter (to, from, next) {
    $api.getMyShopWebSettings()
      .then(res => res.data)
      .then(data => {
        if (!data) {
          return next(from);
        }

        next(vm => {
          vm.settings = data;
        });
      })
      .catch(() => next(from))
    ;
  },
};

</script>
